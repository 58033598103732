import React from 'react'
import {graphql} from 'gatsby'
import PageHolder from '../components/organisms/PageHolder'
import SEO from '../components/molecules/SEO'
import Container from '../components/atoms/Container'
import styled from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'
import RichTextConverter from '../components/atoms/RichTextConverter'
import MarkdownConverter from '../components/atoms/MarkdownConverter'

const HeroText = styled.div`
  margin-bottom: 6rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 1rem;
    margin: 4rem 0;
  }

  h1 {
    ${props => props.theme.fluidType(1.2)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(0.8)};
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: unset;
    gap: 2rem;
  }
`

const Card = styled.div`
  color: ${props => props.theme.colours.pink};

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  h4 {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    margin: 0;
    font-family: 'ABCWhyteInktrap', Helvetica, Arial, sans-serif;
  }

  p {
    padding-top: 0.34rem;
    font-family: 'Eliza', Helvetica, Arial, sans-serif;
    ${props => props.theme.fluidType(-2)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-1.4)};
    }
  }
`

export default function Files({data}) {
    const {nodes} = data.allContentfulPaper
    return (
        <>
            <PageHolder bg="cream">
                <SEO title="Files"/>
                <Container>
                    <HeroText>
                        <h1>
                            <MarkdownConverter
                                content={data.contentfulGlobalSettings.filesPageHeading.filesPageHeading}/>
                        </h1>
                    </HeroText>
                    <Grid>
                        {nodes.map((papers, i) => {
                            return <Card key={i}>
                                {papers.file.url ?
                                    <a href={papers.file.url} rel="noreferrer noopener" target="_blank">
                                        <div style={{position: 'relative'}}>
                                            <h4>{papers.title}</h4>
                                            <GatsbyImage alt={papers.image.title} image={papers.image.gatsbyImageData}/>
                                        </div>
                                        <RichTextConverter content={papers.description}/>
                                    </a>
                                    :
                                    <>
                                        <div style={{position: 'relative'}}>
                                            <h4>{papers.title}</h4>
                                            <GatsbyImage alt={papers.image.title} image={papers.image.gatsbyImageData}/>
                                        </div>
                                        <RichTextConverter content={papers.description}/>
                                    </>
                                }
                            </Card>
                        })}
                    </Grid>
                </Container>
            </PageHolder>
        </>
    )
}


export const pageQuery = graphql`
  {
    allContentfulPaper(sort: {fields: createdAt}) {
        nodes {
          title
          image {
            gatsbyImageData
            title
          }
          file {
            publicUrl
            url
          }
          description {
            raw
          }
        }
    }
    contentfulGlobalSettings(title: {eq: "Global Settings"}) {
      filesPageHeading {
        filesPageHeading
      }
    }
  }
`